/**
 * @class ContextSearch
 * React Component ~
 */

import * as React from 'react';
import styles from './styles.css';
import OptionsPanel from './components/OptionsPanel';
import ResultsPanel from './components/ResultsPanel';
import { motion, AnimatePresence } from 'framer-motion';

import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

export const DISPLAY_OPTIONS = 'DISPLAY_OPTIONS';
export const DISPLAY_RESULTS = 'DISPLAY_RESULTS';

const DEBOUNCE_TIME = 500;

const PanelComponent = ({ isVisible, content }: { isVisible: boolean; content: any }) => {
	return (
		<AnimatePresence>
			{isVisible && (
				<motion.div
					className={styles.panel__container}
					key="modal"
					initial={{ opacity: 0 }} // y: "-100%",
					animate={{ opacity: 1 }} // y: "0%",
					exit={{ opacity: 0 }} // y: "-100%",
					transition={{ ease: 'easeOut', duration: 0.2 }}
				>
					{content}
				</motion.div>
			)}
		</AnimatePresence>
	);
};

type IInputProps = {
	placeholder?: string;
};

export interface IFilter {
	label: string
	type: string
	resultTemplate: string
  }

interface ContextSearchProps {
	inputProps?: IInputProps;
	options: any;
	onSelectedOption: Function;
	onSelectResult?: Function;
	onEmitSearchCriteriaOnEnter: Function;
	onEmitSearchResultsObservable: Function;
	isLoading: boolean;
	currentFilter?: IFilter;
	currentSearchCriteria?: string;
	currentResultList?: object & any;
}

interface ContextSearchState {
	inputProps: IInputProps | null;
	displayPanel: boolean;
	currentFilter: IFilter | null;
	currentInputValue: string | undefined;
	panelMode: string;
	resultsTemplate: string
}

export default class ContextSearch extends React.Component<ContextSearchProps, ContextSearchState> {
	state = {
		inputProps: null,
		displayPanel: false,
		currentFilter: { label: '', type: '', resultTemplate: '' },
		currentInputValue: '',
		resultsTemplate: '',
		panelMode: DISPLAY_OPTIONS
	};
	wrapperNode: any;
	mainInputNode: any;
	onSearch$ = new Subject();
	subscription: any;

	constructor(props: ContextSearchProps) {
		super(props);
		this.wrapperNode = React.createRef();
		this.mainInputNode = React.createRef();
	}

  /**
   * @return void
   * check if has the correct properties
   */
	componentWillMount() {
		if (!this.props.options || !this.props.onSelectedOption) throw new Error('Invalid component props');

		/** input search suscription */
		this.subscription = this.onSearch$.pipe(debounceTime(DEBOUNCE_TIME)).subscribe((debounced: any) => {
			if (debounced !== '' && debounced !== null && typeof debounced !== undefined) {
				this.props.onEmitSearchResultsObservable(debounced);
			}
		});
	}

	componentDidMount() {
		window.document.addEventListener('mousedown', this.handleDocumentClick);

		if(this.props.inputProps) this.setState({inputProps: this.props.inputProps})
		if (this.props.currentSearchCriteria) this.setState({ currentInputValue: this.props.currentSearchCriteria });
		if (this.props.currentFilter && this.props.currentFilter.label && this.props.currentFilter.type) {
			this.setState({ currentFilter: this.props.currentFilter });
		}
		if (this.props.currentResultList && this.props.currentResultList.items.length)
			this.setState({ panelMode: DISPLAY_RESULTS });
	}

	componentWillUnmount() {
		window.document.removeEventListener('mousedown', this.handleDocumentClick, false);
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}

	componentDidUpdate(prevProps: ContextSearchProps) {
		if (prevProps.currentResultList != this.props.currentResultList) {
			const panelMode = this.props.currentResultList ? DISPLAY_RESULTS : DISPLAY_OPTIONS;
			this.setState({ panelMode });
		}
	}

	handleDocumentClick = (e: any) => {
		if (this.wrapperNode.current.contains(e.target)) {
			return;
		}
		this.setState({ displayPanel: false });
	};

	onDisplayPanel = (e: any) => {
		e.preventDefault();
		this.setState({ displayPanel: true });
	};

	onHiddenPanel = (e: any) => {
		e.preventDefault();
		this.setState({ displayPanel: false });
	};

  /**
   * onSelectResult ()
   * Return value of selected option form icon menu
   */
	onSelectOption = (filter: IFilter) => {
		this.props.onSelectedOption( filter );
		this.mainInputNode.current.focus();
		this.setState({ currentFilter: filter, inputProps: {...this.props.inputProps} });
	};

  /**
   * onSelectResult ()
   * Return value of selected item from results list
   */
	onSelectResult = (value: object) => {
		if (this.props.onSelectResult) this.props.onSelectResult(value);
		else console.log(value);
	};

	onRemoveSelectedOption = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
		e.preventDefault();
		this.setState({ currentFilter: null, panelMode: DISPLAY_OPTIONS });
	};

	handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		const value = e.target['value'];
		if (e['key'] === 'Enter' && value.length) {
			console.log('Enter keydown!, do something !!!', value);
			this.props.onEmitSearchCriteriaOnEnter(value);
		}
	};

	handleChange = (e: React.FormEvent<HTMLInputElement>) => {
		if (this.state.currentFilter === null) {
			e.preventDefault();
			e.stopPropagation();
			this.setState({
				currentInputValue: '',
				inputProps: {...this.props.inputProps, placeholder: "Selecciona una opción"}
			});
		} else {
			this.setState({ currentInputValue: e.currentTarget.value, inputProps: {...this.props.inputProps} });
			this.onSearch$.next(e.currentTarget.value);
		}
	};

	resetPanelOptions = () => {
		this.setState({panelMode: DISPLAY_OPTIONS})
	};

	replaceTokensByValues = (currentFilter: IFilter, item: any) => {
		let resultString = currentFilter.resultTemplate;
		switch(currentFilter.type) {
			case "course":
				resultString = resultString.replace('${CODE}', item.code)
					.replace('${NAME}', item.name) 
				break;
			case "section":
				resultString = resultString
					.replace("${ID}", item.id)
					.replace("${COURSE_NAME}", item.course.name)
					.replace("${COURSE_CODE}", item.course.code)
					.replace("${COMPONENT_CODE}", item.courseComponent.component.code)
				break;
			case "population":
				resultString = resultString
					.replace("${CODE}", item.code)
					.replace("${PROGRAM_NAME}", item.program.name)
					.replace("${CURRICULUM_NAME}", item.curriculum)
					.replace("${LEVEL}", item.level)
				break;
			case "package":
				resultString = resultString
					.replace("${CODE}", item.code)
					.replace("${PROGRAM_NAME}", item.population.program.name)
					.replace("${POPULATION_CURRICULUM}", item.population.curriculum)
					.replace("${POPULATION_LEVEL}", item.population.level)
				break;
			case "instructor":
				resultString = resultString
					.replace("${NAME}", item.name)
					.replace("${CODE}", item.code)
				break;
			case "classroom":
				resultString = resultString
					.replace("${BUILDING_NAME}", item.building.name)
					.replace("${CODE}", item.code)
					.replace("${CAPACITY}", item.capacity)
					.replace("${ALLOWEDOVERFLOW}", item.allowedOverflow)
					.replace("${CLASSROOMTYPE_CODE}", item.classroomType.code)
				break;
		}

		return resultString
	}

	getPanel = (panelMode: string, currentFilter: any) => {
		let panel;
		console.log('PANEL MODE > ', panelMode);
		switch (panelMode) {
			case DISPLAY_OPTIONS:
				panel = <OptionsPanel onSelectOption={this.onSelectOption} options={this.props.options} />;
				break;
			case DISPLAY_RESULTS:
				console.log("times + times")
				const results = this.props.currentResultList.items;
				results.map((item: any) => {
					item['label'] = this.replaceTokensByValues(currentFilter, item)
				});
				panel = (
					<ResultsPanel
						resetPanelOptions={this.resetPanelOptions}
						onSelectedResult={this.onSelectResult}
						results={results}
					/>
				);
				break;
		}
		return panel;
	};

	render() {
		const { isLoading } = this.props;
		const {
			inputProps,
			currentFilter,
			displayPanel,
			currentInputValue,
			panelMode
		} = this.state;

		return (
			<div style={{ display: 'block' }}>
				<div
					className={`${styles.contextSearch__wrapper} ${displayPanel
						? styles.contextSearch__wrapper_active
						: ''}  ${isLoading ? styles.loading__state : ''}`}
					ref={this.wrapperNode}
				>
					{(currentFilter && currentFilter.label) && (
						<label className={styles.label_choice__item}>
							Buscar por <span style={{ textTransform: 'lowercase' }}> {currentFilter.label} </span>
							<a
								className={styles.label_choice__remove_btn}
								onClick={(e) => this.onRemoveSelectedOption(e)}
							>
								&nbsp;
							</a>
						</label>
					)}
					<input
						type="text"
						value={currentInputValue}
						className={(currentFilter && currentFilter.label) ? styles.with_choice__selected : ''}
						onChange={this.handleChange}
						onFocus={this.onDisplayPanel}
						onKeyDown={this.handleKeyDown}
						ref={this.mainInputNode}
						{...inputProps}
					/>
					<PanelComponent isVisible={displayPanel} content={this.getPanel(panelMode, currentFilter)} />
				</div>
			</div>
		);
	}
}
